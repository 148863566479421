//@ts-nocheck
import React from "react";
import MySubscriptionsController, {
  Props,
} from "./MySubscriptionsController.web";
import {
  Typography,
  Button,
  Grid,
  withStyles,
  createStyles,
  AppBar,
  Toolbar,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Checkbox,
} from "@material-ui/core";
import { withRouter } from "react-router";
import Alert from "@material-ui/lab/Alert";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { Formik, Form } from "formik";
import moment from "moment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import SubscriptionFeatureCard from "../../../components/src/SubscriptionFeatureCard.web";
import PlusMinusButton from "../../../components/src/PlusMinusButton.web";
import CustomGrid from "../../../components/src/CustomGrid.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CancelSubscriptionDialog from "./CancelSubscriptionDialog.web";
import MySubscriptionsFeatureDialog from "./MySubscriptionsFeatureDialog.web";
import { LightenDarkenColor } from "./utils.js";
import withPermissions from "../../../components/src/withPermissions.web";
import AddSignatureDialog from "./AddSignatureDialog.web";
export class MySubscriptions extends MySubscriptionsController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
        this.getProfile();
      }
    );
  }

  openCancelSubscriptionDialog = (data) => {
    this.props
      .openDialogBox({
        dataToPass: {
          package: data,
        },
        width: "60%",
        renderedComponent: CancelSubscriptionDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data) {
          this.cancelMyCurrentSubscription();
        }
      });
  };

  openFeatureInfoDialog = (data) => {
    this.props
      .openDialogBox({
        dataToPass: {
          dialogTitle: data.dialogTitle,
          dialogMessage: data.dialogMessage,
        },
        width: "60%",
        renderedComponent: MySubscriptionsFeatureDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data) {
          this.props.showToast({
            type: "success",
            message: "Image updated succssfully",
          });
        }
      });
  };
  getOneTimeFessForToBeUpdatedSubuscription = (totalOneTimeFess) => {
    let oneTimefees = totalOneTimeFess;
    if (this.state.managingSubscription) {
      const blueprintfees = this.state.subscriptionInitialValues
        ?.isSmartBlurPrint
        ? 495
        : 0;
      const smart_implementation_fees = this.state.subscriptionInitialValues
        ?.isSmartImplimentation
        ? 2995
        : 0;
      oneTimefees =
        totalOneTimeFess - blueprintfees - smart_implementation_fees;
    }
    return oneTimefees;
  };
  getAddonBoxes = (values, setFieldValue) => {
    return [
      {
        label: (
          <Typography
            display="inline"
            style={{
              fontSize: "1.2rem",
            }}
          >
            Signature Bundles $22 for{" "}
            <PlusMinusButton
              initialValue={values.signatureBundleCount}
              disableValue={1}
              isDisable={
                !values?.planRadioButton ||
                values?.planRadioButton ==
                this.state?.subscriptionInitialValues?.planRadioButton ||
                (this.state?.subscriptionInitialValues?.issignatureBundle &&
                  this.state.subscribed)
              }
              onDecrement={(event) => {
                event.stopPropagation()
                setFieldValue(
                  "signatureBundleCount",
                  +values.signatureBundleCount - 1
                );
              }}
              onIncrement={(event) => {
                event.stopPropagation()
                setFieldValue(
                  "signatureBundleCount",
                  +values.signatureBundleCount + 1
                );
              }}
            />{" "}
            Automated Client Enrollments (per bundle 20)
          </Typography>
        ),
        isDisable:
          !values?.planRadioButton ||
          values?.planRadioButton ==
          this.state?.subscriptionInitialValues?.planRadioButton,
        name: "issignatureBundle",
        id: 2222,
      },
      {
        label: (
          <Typography
            display="inline"
            style={{
              fontSize: "1.2rem",
            }}
          >
            Additional Users: 1 User is included with the subscription. I want{" "}
            <PlusMinusButton
              isDisable={
                !values?.planRadioButton ||
                values?.planRadioButton ==
                this.state?.subscriptionInitialValues?.planRadioButton
              }
              initialValue={values.additionalUsersCount}
              disableValue={1}
              onDecrement={(event) => {
                event.stopPropagation()
                setFieldValue(
                  "additionalUsersCount",
                  +values.additionalUsersCount - 1
                );
              }}
              onIncrement={(event) => {
                event.stopPropagation()
                setFieldValue(
                  "additionalUsersCount",
                  +values.additionalUsersCount + 1
                );
              }}
            />{" "}
            additional user(s) for $40/month each
          </Typography>
        ),
        isDisable:
          !values?.planRadioButton ||
          values?.planRadioButton ==
          this.state?.subscriptionInitialValues?.planRadioButton,
        name: "isAdditionaUser",
        id: 3333,
      },
      {
        label: "Smart Blueprint $495 One Time",
        id: 4444,
        name: "isSmartBlurPrint",
        isDisable: !values?.planRadioButton
          ? true
          : this.state?.subscriptionInitialValues?.isSmartBlurPrint ||
          values?.planRadioButton ==
          this.state?.subscriptionInitialValues?.planRadioButton,
      },
      {
        label: "Smart Implementation $2,995 One Time",
        id: 5555,
        isSmartImplimentation: false,
        name: "isSmartImplimentation",
        isDisable: !values?.planRadioButton
          ? true
          : this.state?.subscriptionInitialValues?.isSmartImplimentation ||
          values?.planRadioButton ==
          this.state?.subscriptionInitialValues?.planRadioButton,
      },
      {
        label: "Smart Support $625/month",
        id: 6666,
        isSmartSupport: false,
        name: "isSmartSupport",
        isDisable:
          !values?.planRadioButton ||
          values?.planRadioButton ==
          this.state?.subscriptionInitialValues?.planRadioButton,
      },
      {
        label: "Smart Lead $925/month",
        id: "7777",
        smart_lead: false,
        name: "smart_lead",
        isDisable:
          !values?.planRadioButton ||
          values?.planRadioButton ==
          this.state?.subscriptionInitialValues?.planRadioButton,
      },
    ];
  };
  getSubuscriptionFeesPlan = (values) => {
    let subuscriptionFees = "";
    let subuscriptionFeesPlan = "";
    if (values?.selectedPackage?.name === "Engage") {
      if (values?.planRadioButton === "engageMonthly") {
        subuscriptionFees = 95 + "/month";
        subuscriptionFeesPlan = "/month";
      } else if (values?.planRadioButton === "engageAnnual") {
        subuscriptionFees = 95 * 12 - 95 * 12 * 0.2 + "/year";
        subuscriptionFeesPlan = "/year";
      }
    } else if (values?.selectedPackage?.name === "Advise") {
      if (values?.planRadioButton === "adviceMonthly") {
        subuscriptionFees = 225 + "/month";
        subuscriptionFeesPlan = "/month";
      } else if (values?.planRadioButton === "adviceAnnual") {
        subuscriptionFees = 225 * 12 - 225 * 12 * 0.2 + "/year";
        subuscriptionFeesPlan = "/year";
      }
    }
    return { subuscriptionFees, subuscriptionFeesPlan };
  };
  getTotalRecurringFeesBySelectedPackage = (values) => {
    let totalRecurringFees = 0;
    if (values?.selectedPackage?.name === "Engage") {
      if (values?.planRadioButton === "engageMonthly") {
        totalRecurringFees += 95;
      } else if (values?.planRadioButton === "engageAnnual") {
        totalRecurringFees += 95 * 12 - 95 * 12 * 0.2;
      }
    } else if (values?.selectedPackage?.name === "Advise") {
      if (values?.planRadioButton === "adviceMonthly") {
        totalRecurringFees += 225;
      } else if (values?.planRadioButton === "adviceAnnual") {
        totalRecurringFees += 225 * 12 - 225 * 12 * 0.2;
      }
    }
    return totalRecurringFees;
  };
  getTotalRecurringFeesBySelectedAddons = (values, totalRecurringFee) => {
    let totalRecurringFees = totalRecurringFee;
    if (values?.isAdditionaUser) {
      if (values?.planRadioButton.indexOf("Monthly") > -1) {
        totalRecurringFees += 40 * +values.additionalUsersCount;
      } else if (values?.planRadioButton.indexOf("Annual") > -1) {
        totalRecurringFees += 40 * +values.additionalUsersCount * 12;
      } else {
        totalRecurringFees += 40 * +values.additionalUsersCount;
      }
    }
    if (values?.isSmartSupport) {
      if (values?.planRadioButton.indexOf("Monthly") > -1) {
        totalRecurringFees += 625;
      } else if (values?.planRadioButton.indexOf("Annual") > -1) {
        totalRecurringFees += 625 * 12;
      } else {
        totalRecurringFees += 625;
      }
    }
    if (values.smart_lead) {
      if (values?.planRadioButton.indexOf("Monthly") > -1) {
        totalRecurringFees += 925;
      } else if (values?.planRadioButton.indexOf("Annual") > -1) {
        totalRecurringFees += 925 * 12;
      } else {
        totalRecurringFees += 925;
      }
    }

    return totalRecurringFees;
  };
  getTotalRecurringFeeAndtotalOneTimeFees = (values) => {
    let totalRecurringFees = this.getTotalRecurringFeesBySelectedPackage(
      values
    );
    totalRecurringFees = this.getTotalRecurringFeesBySelectedAddons(
      values,
      totalRecurringFees
    );
    let totalOneTimeFess = 0;
    if (values?.isSmartImplimentation) {
      totalOneTimeFess += 2995;
    }
    if (values?.isSmartBlurPrint) {
      totalOneTimeFess += 495;
    }
    if (values.issignatureBundle) {
      totalOneTimeFess += 22 * values?.signatureBundleCount;
    }
    return { totalRecurringFees, totalOneTimeFess };
  };
  renderProductFeatureList = (product) => {
    return (
      <Grid container justifyContent="center">
        {product?.attributes?.features?.data?.map((feature, index) => {
          return (
            <SubscriptionFeatureCard
              key={feature.id}
              featureName={feature?.attributes?.title}
              padding={true}
              color={index > 3 ? "primary" : "textSecondary"}
              onInfoIconClick={() =>
                this.openFeatureInfoDialog({
                  dialogTitle: feature?.attributes?.title,
                  dialogMessage: feature?.attributes?.description,
                })
              }
            />
          );
        })}
      </Grid>
    );
  };
  getSelectProductClassName = (product, values, classes) => {
    let classNames = null;
    if (product?.attributes?.name === "Engage") {
      if (values.selectedPackage?.name === product?.attributes?.name) {
        classNames = classes.engagePackageSelected;
      } else {
        classNames = classes.engagePackage;
      }
    } else if (product?.attributes?.name === "Advise") {
      if (values.selectedPackage?.name === product?.attributes?.name) {
        classNames = classes.advicePackageSelected;
      } else {
        classNames = classes.advicePackage;
      }
    }
    return classNames;
  };
  renderProductList = (classes, values) => {
    return this.state.productList.length > 0
      ? this.state.productList.map((product) => {
        const priceIndex = product?.attributes?.description.indexOf("When");
        let classNames = this.getSelectProductClassName(
          product,
          values,
          classes
        );
        return (
          <Grid xs={6} key={product?.id} className={classNames}>
            <Grid container justifyContent="center">
              <Grid
                item
                xs={12}
                className={
                  product?.attributes?.name === "Engage"
                    ? [classes.greenCard]
                    : [classes.blueCard]
                }
              >
                <Typography
                  align="center"
                  className={classes.subscriptionBoxHeading}
                >
                  {product?.attributes?.name}
                </Typography>
                <Typography
                  align="center"
                  className={classes.subscriptionBoxSubHeading}
                >
                  {product?.attributes?.description.substring(0, priceIndex)}
                </Typography>
                <Typography
                  align="center"
                  className={classes.subscriptionBoxBodyText}
                >
                  {product?.attributes?.description.substring(priceIndex)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                className={[classes.twoEqualCards, classes.gridSpacing]}
              >
                {this.renderProductFeatureList(product)}
              </Grid>
            </Grid>
          </Grid>
        );
      })
      : null;
  };
  renderRadioBoxGroup = (values, classes, setFieldValue, handleChange) => {
    return (
      <FormControl
        component="fieldset"
        classes={{
          root: classes.formControlRoot,
        }}
      >
        <RadioGroup
          aria-label="gender"
          name="planRadioButton"
          value={values.planRadioButton}
          onChange={(evt) => {
            if (evt.target.value.indexOf("engage") > -1) {
              const EngageId = this.state.productList.filter((pdt) => {
                return pdt.attributes.name === "Engage";
              })[0];
              setFieldValue("selectedPackage", {
                name: "Engage",
                id: EngageId?.id,
              });
            } else if (evt.target.value.indexOf("advice") > -1) {
              const AdviceId = this.state.productList.filter((pdt) => {
                return pdt.attributes.name === "Advise";
              })[0];
              setFieldValue("selectedPackage", {
                name: "Advise",
                id: AdviceId?.id,
              });
            }
            handleChange(evt);
          }}
          classes={{
            root: classes.muiFormGroupRoot,
          }}
        >
          <Grid
            item
            xs={6}
            className={
              values.planRadioButton === "engageMonthly"
                ? classes.selectedCheckboxGridCards
                : classes.checkboxGridCards
            }
          >
            <FormControlLabel
              control={
                <Radio
                  value="engageMonthly"
                  color="primary"
                  icon={<RadioButtonUncheckedIcon fontSize="large" />}
                  checkedIcon={<CheckCircleIcon fontSize="large" />}
                />
              }
              classes={{
                label: classes.addonCheckboxLabel,
              }}

              label="Select Engage Plan (Monthly)"
            />
          </Grid>

          <Grid
            item
            xs={6}
            className={
              values.planRadioButton === "adviceMonthly"
                ? classes.selectedCheckboxGridCards
                : classes.checkboxGridCards
            }
          >
            <FormControlLabel
              control={
                <Radio
                  value="adviceMonthly"
                  color="primary"
                  icon={<RadioButtonUncheckedIcon fontSize="large" />}
                  checkedIcon={<CheckCircleIcon fontSize="large" />}
                />
              }
              classes={{
                label: classes.addonCheckboxLabel,
              }}
              label="Select Advice Plan (Monthly)"
            />
          </Grid>

          <Grid
            item
            xs={6}
            className={
              values.planRadioButton === "engageAnnual"
                ? classes.selectedCheckboxGridCards
                : classes.checkboxGridCards
            }
          >
            <FormControlLabel
              control={
                <Radio
                  value="engageAnnual"
                  color="primary"
                  icon={<RadioButtonUncheckedIcon fontSize="large" />}
                  checkedIcon={<CheckCircleIcon fontSize="large" />}
                />
              }
              classes={{
                label: classes.addonCheckboxLabel,
              }}
              label="Select Engage Plan (Annual Save 20%)"
            />
          </Grid>

          <Grid
            item
            xs={6}
            className={
              values.planRadioButton === "adviceAnnual"
                ? classes.selectedCheckboxGridCards
                : classes.checkboxGridCards
            }
          >
            <FormControlLabel
              control={
                <Radio
                  value="adviceAnnual"
                  color="primary"
                  icon={<RadioButtonUncheckedIcon fontSize="large" />}
                  checkedIcon={<CheckCircleIcon fontSize="large" />}
                />
              }
              classes={{
                label: classes.addonCheckboxLabel,
              }}
              label="Select Advice Plan (Annual Save 20%)"
            />
          </Grid>
        </RadioGroup>
      </FormControl>
    );
  };
  renderAddOnsCard = (addonCheckboxes, values, handleChange, classes) => {
    return this.state.subscribed === false || this.state.managingSubscription
      ? addonCheckboxes.map((item) => {
        return (
          <Grid key={item.id} item container alignItems="center" xs={12}>
            <FormControlLabel

              control={
                <Checkbox
                  checked={values[item?.name]}
                  onChange={handleChange}
                  name={item?.name}
                  disabled={item?.isDisable}
                  color="primary"
                />
              }
              label={item.label}

              classes={{
                label: classes.addonCheckboxLabel,
              }}
            />
          </Grid>
        );
      })
      : null;
  };
  renderCustomGridOne = (values) => {
    return (
      <CustomGrid item xs={6} width="30%">
        <CustomTypography
          color="textSecondary"
          fontSize="1.2rem"
          margin="1rem 0px"
        >
          Current Subscription Level
        </CustomTypography>
        {values?.isAdditionaUser && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            No. Users:
          </CustomTypography>
        )}
        <CustomTypography
          color="textSecondary"
          fontSize="1.2rem"
          margin="1rem 0px"
        >
          Signature Count:
        </CustomTypography>
        {values?.isSmartImplimentation && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            Smart Implementation:
          </CustomTypography>
        )}
        {values?.isSmartBlurPrint && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            Smart BluePrint:
          </CustomTypography>
        )}
        {values?.isSmartSupport && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            Smart Support :
          </CustomTypography>
        )}
        {values?.smart_lead && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            Smart Lead:
          </CustomTypography>
        )}
      </CustomGrid>
    );
  };
  renderCustomGridTwo = (values, subuscriptionFees) => {
    return (
      <CustomGrid item xs={2} width="20%">
        <CustomTypography
          color="textSecondary"
          fontSize="1.2rem"
          margin="1rem 0px"
        >
          {values?.selectedPackage?.name}
        </CustomTypography>
        {values?.isAdditionaUser && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            {values?.additionalUsersCount}
          </CustomTypography>
        )}
        <CustomTypography
          color="textSecondary"
          fontSize="1.2rem"
          margin="1rem 0px"
        >
          0/{values?.signatureBundleCount * 20}
        </CustomTypography>

        {values?.isSmartImplimentation && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            $ 2,995 (One Time)
          </CustomTypography>
        )}
        {values?.isSmartBlurPrint && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            $ 495 (One Time)
          </CustomTypography>
        )}
        {values?.isSmartSupport && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            {subuscriptionFees.indexOf("year") > -1
              ? `$ ${12 * 625} /year`
              : `$ ${625} /month`}
          </CustomTypography>
        )}
        {values?.smart_lead && (
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            {subuscriptionFees.indexOf("year") > -1
              ? `$ ${12 * 925} /year`
              : `$ ${925} /month`}
          </CustomTypography>
        )}
      </CustomGrid>
    );
  };
  openAddSignatureDialog = () => {
    this.props
      .openDialogBox({
        dataToPass: {},
        width: "60%",
        renderedComponent: AddSignatureDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data) {
          this.createSingaturePayment(
            data.signatureCount,
            this.state.subscriptionInitialValues?.selectedPackage?.id,
            this.state.subscriptionInitialValues
          );
        }
      });
  };
  renderCurrentSubscription = (
    values,
    subuscriptionFees,
    totalRecurringFees,
    subuscriptionFeesPlan,
    totalOneTimeFess
  ) => {
    const { classes } = this.props;
    return this.state.subscribed ? (
      <Grid container>
        {this.renderCustomGridOne(values)}
        {this.renderCustomGridTwo(values, subuscriptionFees)}
        <CustomGrid width="30%">
          <CustomTypography
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            $ {totalRecurringFees + subuscriptionFeesPlan} + ${" "}
            {totalOneTimeFess + " One time fees"}
          </CustomTypography>
          {values?.isAdditionaUser && (
            <CustomTypography
              color="textSecondary"
              fontSize="1.2rem"
              margin="1rem 0px"
            >
              ${" "}
              {subuscriptionFees.indexOf("year") > -1
                ? ` ${12 * 40 * values?.additionalUsersCount} /year`
                : ` ${40 * values?.additionalUsersCount} /month`}
            </CustomTypography>
          )}
          <CustomTypography
            style={{ display: "flex", alignItems: "center" }}
            component="div"
            color="textSecondary"
            fontSize="1.2rem"
            margin="1rem 0px"
          >
            <span>$ {22 * +values.signatureBundleCount} One Time</span>
            <Button
              variant="text"
              color="primary"
              onClick={this.openAddSignatureDialog.bind(this)}
              className={classes.addSignatureButton}
              disableRipple
              disableFocusRipple
              disableTouchRipple
            >
              Add Signature
            </Button>
          </CustomTypography>

          {values?.isSmartImplimentation && (
            <CustomTypography
              color="textSecondary"
              fontSize="1.2rem"
              margin="1rem 0px"
            >
              Yes
            </CustomTypography>
          )}
          {values?.isSmartBlurPrint && (
            <CustomTypography
              color="textSecondary"
              fontSize="1.2rem"
              margin="1rem 0px"
            >
              Yes
            </CustomTypography>
          )}
          {values?.isSmartSupport && (
            <CustomTypography
              color="textSecondary"
              fontSize="1.2rem"
              margin="1rem 0px"
            >
              Yes
            </CustomTypography>
          )}
          {values?.smart_lead && (
            <CustomTypography
              color="textSecondary"
              fontSize="1.2rem"
              margin="1rem 0px"
            >
              Yes
            </CustomTypography>
          )}
        </CustomGrid>
      </Grid>
    ) : null;
  };

 

  renderCancelButton = (classes) => {
    const { permissions } = this.props;
    return this.state.subscribed ? (
      <Grid
        item
        xs={3}
        style={{
          flexBasis: "23%",
          maxWidth: "23%",
          marginRight: "2%",
        }}
      >
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          disabled={
            this.state.isSubscriptionAlreadyCanceled ||
            !permissions.includes("Cancel Subscription-Subscriptions")
          }
          onClick={() => {
            this.openCancelSubscriptionDialog();
          }}
          className={classes.buttonClass}
        >
          Cancel My Subscription
        </Button>
      </Grid>
    ) : null;
  };
  renderSubscribeButon = (classes, values) => {
    const { permissions } = this.props;
    let disableSubscribe = true;
    if (
      values?.selectedPackage?.name === "Engage" ||
      values?.selectedPackage?.name === "Advise"
    ) {
      if (values?.planRadioButton) {
        disableSubscribe = false;
      }
    }
    return (
      <Grid item xs={3}>
        {this.state.subscribed ? (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              this.setState({
                managingSubscription: true,
                subscribed: false,
              });
            }}
            disabled={
              this.state.isPlanChangedForNextCycle ||
              this.state.isSubscriptionAlreadyCanceled ||
              !permissions.includes("Manage Subscription-Subscriptions")
            }
            className={classes.buttonClass}
          >
            Manage My Subscription
          </Button>
        ) : (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              values.selectedPackage === null
                ? true
                : disableSubscribe ||
                !permissions.includes("Subscribe-Subscriptions")
            }
            className={classes.buttonClass}
          >
            Subscribe
          </Button>
        )}
      </Grid>
    );
  };
  renderSubscribedBottomBar = (
    classes,
    values,
    totalRecurringFees,
    totalOneTimeFess
  ) => {
    return !this.state.managingSubscription ? (
      <AppBar position="fixed" color="primary" className={classes.appBarStyles}>
        <Toolbar>
          <Grid container>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              style={{
                flexBasis: "77%",
                maxWidth: "77%",
                marginLeft: "18%",
                marginRight: "5%",
              }}
            >
              <Grid item xs={this.state.subscribed ? 6 : 9}>
                <Typography
                  color="primary"
                  align="center"
                  className={classes.priceTextLarge}
                >
                  $ {totalRecurringFees}
                  <Typography
                    display="inline"
                    className={classes.priceTextSmall}
                  >
                    {values?.planRadioButton?.indexOf("Monthly") > -1
                      ? "Monthly"
                      : "Yearly"}{" "}
                    Recurring +
                  </Typography>{" "}
                  $ {totalOneTimeFess}
                  <Typography
                    display="inline"
                    className={classes.priceTextSmall}
                  >
                    One Time
                  </Typography>
                </Typography>
              </Grid>
              {this.renderCancelButton(classes)}
              {this.renderSubscribeButon(classes, values)}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    ) : null;
  };
  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={this.state.subscriptionInitialValues}
        enableReinitialize
        onSubmit={(values) => {
          if (this.state.subscribedData?.attributes?.id) {
            this.updgradMyCurrentSubscription(values);
          } else {
            this.createSubscription(values);
          }
        }}
      >
        {(formikProps) => {
          const { values, handleChange, setFieldValue } = formikProps;
          const fees = this.getTotalRecurringFeeAndtotalOneTimeFees(values);
          const totalRecurringFees = fees?.totalRecurringFees;
          const totalOneTimeFess = fees?.totalOneTimeFess;
          const tempSubuscription = this.getSubuscriptionFeesPlan(values);
          let subuscriptionFees = tempSubuscription?.subuscriptionFees;
          let subuscriptionFeesPlan = tempSubuscription?.subuscriptionFeesPlan;
          const addonCheckboxes = this.getAddonBoxes(values, setFieldValue);
          return (
            <Form autoComplete="off" noValidate>
              <Grid container justifyContent="space-between">
                <Grid item md={12}>
                  <Typography className={classes.title}>
                    My Subscriptions
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={[classes.whiteCard, classes.gridSpacing]}
                >
                  <Grid container justifyContent="center">
                    <Grid xs={10} item>
                      {this.state.isPlanChangedForNextCycle && (
                        <Alert severity="success">
                          {" "}
                          You have updated your subscription level and your new
                          plan will be activated after current subscription ends on{" "}
                          {moment(
                            this.state?.currentSubscription
                              ?.scheduled_subscription_date
                          ).format("DD/MM/YYYY")}.
                        </Alert>
                      )}
                      {this.state.isSubscriptionAlreadyCanceled && (
                        <Alert severity="error">
                          You have cancelled your current subscription on{" "}
                          {moment(
                            this.state?.currentSubscription?.sub_canceled_at
                          ).format("DD/MM/YYYY")}{" "}
                          and all our current services will end on &nbsp;
                          {moment(
                            this.state?.currentSubscription?.sub_cancel_at
                          ).format("DD/MM/YYYY")}.
                        </Alert>
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Grid container justifyContent="space-between">
                        {this.renderProductList(classes, values)}
                        {this.state.subscribed === false &&
                          this.renderRadioBoxGroup(
                            values,
                            classes,
                            setFieldValue,
                            handleChange
                          )}
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={
                        this.state.subscribed &&
                          this.state.managingSubscription === false
                          ? 10
                          : 11
                      }
                      container
                      className={[classes.gridSpacing, classes.darkGreyCard]}
                      style={{
                        marginBottom:
                          this.state.managingSubscription === false
                            ? "2.5rem"
                            : undefined,
                      }}
                    >
                      {this.renderAddOnsCard(
                        addonCheckboxes,
                        values,
                        handleChange,
                        classes
                      )}
                      {this.renderCurrentSubscription(
                        values,
                        subuscriptionFees,
                        totalRecurringFees,
                        subuscriptionFeesPlan,
                        totalOneTimeFess
                      )}
                    </Grid>

                    {this.renderSubscribedBottomBar(
                      classes,
                      values,
                      totalRecurringFees,
                      totalOneTimeFess
                    )}

                    {this.state.managingSubscription ? (
                      <Grid
                        item
                        xs={11}
                        container
                        justifyContent="space-between"
                        className={[classes.gridSpacing]}
                      >
                        <CustomGrid
                          item
                          xs={8}
                          width="69%"
                          container
                          alignItems="center"
                          className={[classes.darkGreyCardWithoutPadding]}
                        >
                          <Typography
                            color="primary"
                            align="center"
                            className={classes.priceTextLarge}
                          >
                            $ {totalRecurringFees}
                            <Typography
                              display="inline"
                              className={classes.priceTextSmall}
                            >
                              {values.planRadioButton &&
                                values.planRadioButton.indexOf("Monthly") === -1
                                ? "Yearly"
                                : "Monthly"}
                              Recurring +
                            </Typography>{" "}
                            ${" "}
                            {this.getOneTimeFessForToBeUpdatedSubuscription(
                              totalOneTimeFess
                            )}
                            <Typography
                              display="inline"
                              className={classes.priceTextSmall}
                            >
                              One Time
                            </Typography>
                          </Typography>
                        </CustomGrid>
                        <CustomGrid item xs={3} width="29%">
                          <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={
                              values?.planRadioButton ===
                              this.state.subscriptionInitialValues
                                ?.planRadioButton
                            }
                            className={classes.applyChangesButton}
                          >
                            Apply Changes
                          </Button>
                        </CustomGrid>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const MySubscriptionsWithRouter = withRouter(MySubscriptions);
const MySubscriptionsAlertBox = withAlertBox(MySubscriptionsWithRouter);
const MySubscriptionsLoader = withLoader(MySubscriptionsAlertBox);
const MySubscriptionsToast = withToast(MySubscriptionsLoader);
const MySubscriptionsWithDialog = withDialog(MySubscriptionsToast);
const MySubscriptionsWithConfirmBox = withConfirmBox(MySubscriptionsWithDialog);
const MySubscriptionsWithPermissions = withPermissions(
  MySubscriptionsWithConfirmBox
);

export default withStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(3, 3),
        maxHeight: "calc(100vh - 60px)",
        overflowY: "auto",
      },
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: "#ffffff",
        boxShadow: "rgba(149, 157, 165, 0.35) 0px 0px 16px",
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      lightGreyCard: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: "#f8f8f8",
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      checkboxGridCards: {
        padding: "0.5rem",
        borderRadius: "1rem",
        backgroundColor: "#f8f8f8",
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "1rem",
        paddingLeft: "2rem",
        border: `2px solid #f8f8f8`,
        display: "flex",
        alignItems: "center",
      },
      selectedCheckboxGridCards: {
        padding: "0.5rem",
        borderRadius: "1rem",
        backgroundColor: "#f8f8f8",
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "1rem",
        paddingLeft: "2rem",
        border: `2px solid ${theme.palette.primary.main}`,
        display: "flex",
        alignItems: "center",
      },
      darkGreyCard: {
        padding: "1rem",
        borderRadius: "1rem",
        backgroundColor: "#F8F8F8",
        paddingLeft: "2rem",
      },
      darkGreyCardWithoutPadding: {
        borderRadius: "12px",
        backgroundColor: "#f6f6f6",
      },
      title: {
        fontFamily: "Nunito Sans",
        fontSize: "1.75rem",
        color: "#54A4A6",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
      },
      blueTitle: {
        fontFamily: "Nunito Sans",
        fontSize: "1.75rem",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        marginBottom: theme.spacing(1),
        color: theme.palette.text.blue2,
      },
      subTitle: {
        fontFamily: "Nunito Sans",
        fontSize: "1rem",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: theme.palette.text.blue2,
      },
      greyCardTitle: {
        fontFamily: "Nunito Sans",
        fontSize: "1.75rem",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: theme.palette.text.blue2,
      },
      clientListColorBar: {
        position: "absolute",
        left: 0,
        top: "30%",
        width: "4px",
        height: "40%",
        backgroundColor: theme.palette.primary.main,
      },
      greenCard: {
        padding: theme.spacing(2, 4),
        borderRadius: "1rem",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
      },
      blueCard: {
        padding: theme.spacing(2, 4),
        borderRadius: "1rem",
        backgroundColor: theme.palette.text.blue2,
        color: "#fff",
      },
      twoEqualCards: {
        flexBasis: "98%",
        maxWidth: "98%",
      },
      smallWhiteCards: {
        flexBasis: "96%",
        maxWidth: "96%",
        margin: theme.spacing(1, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      smallWhiteCardsHalfWidth: {
        flexBasis: "48.5%",
        maxWidth: "48.5%",
        margin: theme.spacing(1, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      smallCardsText: {
        fontFamily: "Nunito Sans",
        fontSize: "18px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
      },
      subscriptionBoxHeading: {
        fontFamily: "Nunito Sans",
        fontSize: "1.5rem",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        marginBottom: theme.spacing(2),
      },
      subscriptionBoxSubHeading: {
        fontFamily: "Nunito Sans",
        fontSize: "1.3rem",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
      },
      subscriptionBoxBodyText: {
        fontFamily: "Nunito Sans",
        fontSize: "1.2rem",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
      },
      packageCard: {
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "3rem",
      },
      engagePackage: {
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "3rem",
        border: `1px solid transparent`,
        borderRadius: "1rem",
      },
      advicePackage: {
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "3rem",
        border: `1px solid transparent`,
        borderRadius: "1rem",
      },
      engagePackageSelected: {
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "3rem",
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "1rem",
      },
      advicePackageSelected: {
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "3rem",
        border: `1px solid ${theme.palette.text.blue2}`,
        borderRadius: "1rem",
      },
      buttonGrids: {
        flexBasis: "48%",
        maxWidth: "48%",
        marginTop: "1rem",
      },
      engagePlanButton: {
        height: "3.8rem",
        fontSize: "1.3rem",
      },
      advicePlanButton: {
        height: "3.8rem",
        fontSize: "1.3rem",
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
          backgroundColor: LightenDarkenColor(
            theme.palette.secondary.main,
            -20
          ),
        },
      },
      currentPlanButton: {
        backgroundColor: "#EBF3F5",
        color: "#A0A0A0	",
        height: "3.8rem",
        fontSize: "1.3rem",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: LightenDarkenColor("#EBF3F5", -20),
          boxShadow: "none",
        },
      },
      downgradePlanButton: {
        backgroundColor: "#8F92A1",
        color: theme.palette.common.white,
        height: "3.8rem",
        fontSize: "1.3rem",
        "&:hover": {
          backgroundColor: LightenDarkenColor("#8F92A1", -20),
        },
      },
      applyChangesButton: {
        height: "4.4rem",
        fontSize: "1.2rem",
      },
      priceTextLarge: {
        fontFamily: "Nunito Sans",
        fontSize: "2.4rem",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: theme.palette.primary.main,
        paddingLeft: "2rem",
      },
      priceTextSmall: {
        fontFamily: "Nunito Sans",
        fontSize: "1.2rem",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: theme.palette.text.secondary,
      },
      appBarStyles: {
        width: "100%",
        top: "auto",
        bottom: 0,
        backgroundColor: " #ffffff",
        padding: "0.5rem 0",
      },
      toolbar: {
        flexWrap: "nowrap",
        margin: theme.spacing(0, 4, 0, 4),
        minHeight: 0,
        padding: "10px 0px",
      },
      addonCheckboxLabel: {
        color: theme.palette.text.secondary,
        fontSize: "1.2rem",
      },
      buttonClass: {
        height: "3.2rem",
        fontSize: "1.2rem",
      },
      formControlRoot: {
        width: "100%",
      },
      muiFormGroupRoot: {
        flexDirection: "row",
        justifyContent: "space-between",
        "& .MuiSvgIcon-root": {
          width: "1.5em",
          height: "1.5em",
        },
      },
      addSignatureButton: {
        padding: "0 1rem",
        height: "auto"
      }
    }),
  { withTheme: true }
)(MySubscriptionsWithPermissions);
